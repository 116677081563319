import { t } from '@zupr/i18n'
import { aggregationKeys, filterKeys } from '@zupr/next/helpers/products'
import { Aggregations, Category, Filter, Variation } from '@zupr/types/fo'
import { List } from '@zupr/types/generic'
import { formatPrice } from '@zupr/utils/price'
import { useRouter } from 'next/router'
import { useCallback, useContext, useMemo } from 'react'
import sluggify from 'slug'

import AreaContext from '../../../context/domain'
import RouteContext from '../../../context/route'
import { useIsMobile } from '../../../context/ux'
import PaginationSort from '../../../shared/components/pagination/sort'
import Trans from '../../../shared/components/trans'
import AutocompleteFilter, {
    RemoveAutocompleteFilter,
} from '../../../shared/filters/autocomplete'
import FilterBox from '../../../shared/filters/box'
import FilterCollapse from '../../../shared/filters/collapse'
import RemoveFilter, { RemoveKeys } from '../../../shared/filters/remove'
import FilterContainer from '../../components/search/filter/container'
import FilterLinks, { FilterLink } from '../../components/search/filter/link'
import Multiselect from '../../components/search/filter/multiselect'
import ProductsCategories from './filters/category'
import Color from './filters/color'
import DiscountFilter from './filters/discount'
import FeatureFilters from './filters/features'
import PriceFilter from './filters/price-filter'
import ShopperLocationFilter, {
    RemoveShopperLocation,
} from './filters/shopper-location'
import StockFilters from './filters/stock'

import '../../../../scss/react/filter/filters.scss'

const filterPriceBuckets = (aggregations, key) => {
    const filtered = aggregations?.filtered || aggregations
    return filtered?.[key]?.buckets.filter((bucket) => bucket.key >= 0)
}

const sortSizes = (buckets) => {
    return buckets.sort((a, b) => a.key >= b.key)
}

interface Props {
    aggregations: Aggregations
    products: List<Variation>
    categoryBreadcrumbs: Category[]
    filter: Filter
    categories: Category[]
    activeCategory: Category
    productsAtLocation?: boolean
    baseUrl?: string
    useSlugs?: boolean
}

const ProductsFilters = ({
    aggregations,
    products,
    baseUrl,
    useSlugs,
    categoryBreadcrumbs,
    filter,
    productsAtLocation,
    categories,
    activeCategory,
}: Props) => {
    const { query, push } = useRouter()
    const { changeQuery } = useContext(RouteContext)

    const { shoppingAreaSlug, themes } = useContext(AreaContext)
    const isMobile = useIsMobile()

    const { variables } = filter

    const price = variables[filterKeys.price]
    const priceValue = price && {
        min: parseInt(price.split(':')[0], 10),
        max: parseInt(price.split(':')[1], 10),
    }

    // if value is not false these items are removable in the filter
    const removeable = {
        ordering: query.ordering && t(query.ordering, 'filter'),
        deliverable: t('Deliver'),
        collectable: t('Click & Collect'),
        reservable: t('Reserve'),
        stock: t('In stock'),
    }

    const filterThemes = useCallback(
        (bucket) => bucket.key && themes?.includes(bucket.key),
        [themes]
    )

    const themeAggregations = useMemo(
        () =>
            (aggregations?.data &&
                !variables[filterKeys.theme] &&
                aggregations.data[aggregationKeys.themes]?.buckets?.filter(
                    filterThemes
                )) ||
            [],
        [aggregations, filterThemes, variables]
    )

    const priceBuckets = filterPriceBuckets(
        aggregations.data,
        aggregationKeys.price
    )

    const data = aggregations.data || {}

    const onHandlePrice = useCallback(
        ({ min, max }) => {
            push(
                changeQuery({
                    price: `${min}:${max}`,
                })
            )
        },
        [changeQuery, push]
    )

    return (
        <FilterContainer
            resultCount={products?.count}
            filterCount={filter.filterCount}
            renderFilters={
                <div className="filters">
                    {filter.filterCount > 0 && (
                        <FilterCollapse
                            header={
                                <>
                                    <h4>
                                        <Trans label="Gekozen filters" />
                                    </h4>
                                    <RemoveKeys
                                        href={baseUrl}
                                        keys={[
                                            'ordering',
                                            ...Object.keys(filterKeys),
                                        ]}
                                    />
                                </>
                            }
                        >
                            {variables[filterKeys.theme] && (
                                <RemoveFilter href={baseUrl} filterKey="theme">
                                    {t(variables[filterKeys.theme], 'themes')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.discount] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="discount"
                                >
                                    {t('Actieproducten', 'dutch')}
                                </RemoveFilter>
                            )}

                            {Object.keys(removeable)
                                .filter((key) => !!query[key])
                                .map((key) => (
                                    <RemoveFilter filterKey={key}>
                                        {removeable[key]}
                                    </RemoveFilter>
                                ))}

                            {variables[filterKeys.price] && (
                                <RemoveFilter filterKey="price">
                                    <Trans label="Price" />
                                    {` (${formatPrice(
                                        priceValue.min,
                                        'EUR',
                                        'NL'
                                    )} - ${formatPrice(
                                        priceValue.max,
                                        'EUR',
                                        'NL'
                                    )})`}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.color]
                                ?.split(',')
                                .map((color) => (
                                    <RemoveFilter
                                        key={color}
                                        filterKey="color"
                                        removeFromValue={color}
                                        value={variables[filterKeys.color]}
                                    >
                                        <Trans label={color} context="colors" />
                                    </RemoveFilter>
                                ))}

                            {variables[filterKeys.size]
                                ?.split(',')
                                .map((size) => (
                                    <RemoveFilter
                                        key={size}
                                        filterKey="size"
                                        removeFromValue={size}
                                        value={variables[filterKeys.size]}
                                    >
                                        <Trans label="Size" />
                                        {': '}
                                        {size}
                                    </RemoveFilter>
                                ))}

                            {variables[filterKeys.brands] && (
                                <RemoveAutocompleteFilter
                                    url="fo/brand"
                                    filterKey="brands"
                                />
                            )}

                            {variables[filterKeys.box] && (
                                <RemoveShopperLocation />
                            )}

                            {variables[filterKeys.locations] && (
                                <RemoveAutocompleteFilter
                                    url="fo/location"
                                    filterKey="locations"
                                />
                            )}

                            {variables[filterKeys.demographic]
                                ?.split(',')
                                .map((demographic) => (
                                    <RemoveFilter
                                        key={demographic}
                                        filterKey="demographic"
                                        removeFromValue={demographic}
                                        value={
                                            variables[filterKeys.demographic]
                                        }
                                    >
                                        <Trans label={demographic} />
                                    </RemoveFilter>
                                ))}
                        </FilterCollapse>
                    )}
                    {isMobile && (
                        <FilterBox>
                            <PaginationSort
                                defaultOrdering="-product_locations.created"
                                choices={[
                                    'product_locations.price',
                                    '-product_locations.price',
                                    '-product_locations.created',
                                ]}
                            />
                        </FilterBox>
                    )}

                    <StockFilters query={query} />

                    <FeatureFilters
                        aggregations={aggregations}
                        variables={variables}
                    />

                    {!productsAtLocation && (
                        <ShopperLocationFilter variables={variables} />
                    )}

                    <DiscountFilter
                        aggregations={aggregations}
                        variables={variables}
                    />

                    {isMobile && (
                        <ProductsCategories
                            baseUrl={baseUrl}
                            useSlugs={useSlugs}
                            categories={categories}
                            activeCategory={activeCategory}
                            categoryBreadcrumbs={categoryBreadcrumbs}
                        />
                    )}

                    {filter.filterCount > 0 &&
                        data[aggregationKeys.demographic] && (
                            <FilterCollapse header="Doelgroep">
                                <FilterLinks
                                    value={variables[filterKeys.demographic]}
                                    filterKey="demographic"
                                    aggregationKey={aggregationKeys.demographic}
                                    buckets={
                                        data[aggregationKeys.demographic]
                                            .buckets
                                    }
                                    formatLabel={(demographic) => (
                                        <Trans label={demographic} />
                                    )}
                                />
                            </FilterCollapse>
                        )}

                    {/* No location filter on location products page */}
                    {!variables[filterKeys.location] && (
                        <AutocompleteFilter
                            url="fo/location"
                            label="Locations"
                            filterKey="locations"
                            initialLimit={0}
                            variables={{
                                shopping_areas: shoppingAreaSlug,
                            }}
                        />
                    )}

                    {themeAggregations.length >= 1 && (
                        <FilterCollapse header="Themes">
                            {themeAggregations.map((bucket) => {
                                const count =
                                    bucket['reverse.product_locations.themes']
                                        .doc_count

                                if (!useSlugs) {
                                    return (
                                        <FilterLink
                                            key={bucket.key}
                                            query={{ theme: bucket.key }}
                                            href={baseUrl}
                                            count={count}
                                        >
                                            {t(bucket.key, 'themes')}
                                        </FilterLink>
                                    )
                                }
                                return (
                                    <FilterLink
                                        key={bucket.key}
                                        href={`/${sluggify(
                                            t(bucket.key, 'themes')
                                        )}`}
                                        count={count}
                                    >
                                        {t(bucket.key, 'themes')}
                                    </FilterLink>
                                )
                            })}
                        </FilterCollapse>
                    )}

                    {filter.filterCount > 0 &&
                        aggregationKeys.size &&
                        data[aggregationKeys.size] && (
                            <FilterCollapse
                                startCollapsed={!variables[filterKeys.size]}
                                header="Sizes"
                            >
                                <Multiselect
                                    value={variables[filterKeys.size]}
                                    filterKey="size"
                                    aggregationKey={aggregationKeys.size}
                                    buckets={sortSizes(
                                        data[aggregationKeys.size].buckets
                                    )}
                                />
                            </FilterCollapse>
                        )}

                    {filter.filterCount > 0 &&
                        data[aggregationKeys.materials] && (
                            <FilterCollapse
                                startCollapsed={
                                    !variables[filterKeys.materials]
                                }
                                header="Materials"
                            >
                                <Multiselect
                                    value={variables[filterKeys.materials]}
                                    filterKey="materials"
                                    aggregationKey={aggregationKeys.materials}
                                    formatLabel={(label) =>
                                        t(label, 'material')
                                    }
                                    buckets={sortSizes(
                                        data[aggregationKeys.materials].buckets
                                    )}
                                />
                            </FilterCollapse>
                        )}

                    <AutocompleteFilter
                        url="fo/brand"
                        label="Brands"
                        filterKey="brands"
                        options={data[aggregationKeys.brand]?.buckets?.map(
                            ({ key, doc_count }) => ({
                                id: key,
                                count: doc_count,
                            })
                        )}
                        variables={
                            // if location is set as filter for products
                            // also use it for brands
                            (filter.variables[
                                'product_locations.location.id'
                            ] && {
                                'location.id':
                                    filter.variables[
                                        'product_locations.location.id'
                                    ],
                            }) ||
                            {}
                        }
                    />

                    {filter.filterCount > 0 && data[aggregationKeys.color] && (
                        <FilterCollapse
                            startCollapsed={!variables[filterKeys.color]}
                            header="Color"
                        >
                            <Color
                                value={variables[filterKeys.color]}
                                filterKey="color"
                                aggregationKey={aggregationKeys.color}
                                buckets={data[aggregationKeys.color].buckets}
                                formatLabel={(color) => (
                                    <Trans label={color} context="colors" />
                                )}
                            />
                        </FilterCollapse>
                    )}

                    {!!priceBuckets?.length && (
                        <FilterCollapse header="Price">
                            <PriceFilter
                                key={price || 'pricefilter'}
                                value={priceValue}
                                buckets={priceBuckets}
                                filterKey="price"
                                aggregationKey={aggregationKeys.price}
                                onChange={onHandlePrice}
                            />
                        </FilterCollapse>
                    )}
                </div>
            }
        />
    )
}

ProductsFilters.defaultProps = {
    baseUrl: '/producten',
    useSlugs: false,
}

export default ProductsFilters
